<section 
    class="failed-payment"
    [ngStyle]="{'width': width, 'height': height}"
>

    <span class="spacebetween">
        <img src="../../../assets/images/cancel-white.svg" alt="" style="width: 180px;">
    </span>

    <div class="titre" style="text-transform: uppercase;"> 
        OOOPS! 
    </div>

    <div class="text">
         Nous n'avons pas reçu votre paiement ! 
    </div>
         
    <div class="redirect" (click)='redirect()'>
        Réessayer
    </div>

</section>