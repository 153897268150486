<section 
    class="successful-payment" 
    [ngStyle]="{'width': width, 'height': height}"
>
    <span class="spacebetween">
        <img src="../../../assets/images/check.svg" alt="" style="width: 180px;">
    </span>
    <div class="titre" style="text-transform: uppercase;"> 
        Succès  
    </div>
    <div class="text"> 
        Nous avons bien reçu votre paiement ! 
    </div>
    <div class="redirect" (click)="redirect()"> 
        Continue 
    </div>
</section>