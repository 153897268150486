import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BankingService } from '../../services/banking.service'
import { RedirectPageComponent } from '../redirect-page/redirect-page.component';

@Component({
  selector: 'app-popup-add-founds',
  templateUrl: './popup-add-founds.component.html',
  styleUrls: ['./popup-add-founds.component.css']
})
export class PopupAddFoundsComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data, private bankingService: BankingService,  private router: Router, public dialog: MatDialog) { }

  montant = new FormControl('', [
    Validators.required,
    Validators.min(1),
  ]);

  width;
  height;

  ngOnInit(): void {
    this.width = window.innerWidth + 'px'
    this.height = window.innerHeight + 'px'
  }

  

  validate(){
    let orderIdAndPhoneNumber
    this.dialog.closeAll()
    this.bankingService.getOrderId(this.data.numCard , this.montant.value).subscribe(result => {
      orderIdAndPhoneNumber = result                     
      const url = 'https://eka.lalivraison.ma/paiementcb.php?'+
                   'order_id='+ orderIdAndPhoneNumber.order_id
                 + '&tel='+ orderIdAndPhoneNumber.telephone  
                 + '&nom='+ this.data.name
                 + '&num_carte=' + this.data.numCard
                 +'&montant=' + this.montant.value
      this.dialog.open(RedirectPageComponent, {
        maxWidth: this.width,
        maxHeight: this.height,
        height: this.height,
        width: this.width,
        panelClass: 'full-screen-modal',
        data: {
          url : url
        }
      })
      
      window.location.href = url 
    })  
  }
  
  

}
