import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BankingPageComponent } from './components/banking-page/banking-page.component';
import { MatDialogModule } from "@angular/material/dialog";
import {MatInputModule} from '@angular/material/input'
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PopupAddFoundsComponent } from './components/popup-add-founds/popup-add-founds.component';
import {MatDividerModule} from '@angular/material/divider';
import {MatButtonModule} from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { FailedPaymentComponent } from './components/failed-payment/failed-payment.component';
import { SuccessfulPaymentComponent } from './components/successful-payment/successful-payment.component';
import { PopupAddInfosComponent } from './components/popup-add-infos/popup-add-infos.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';

import { InterceptorService } from '../app/services/interceptor.service';
import { RedirectPageComponent } from './components/redirect-page/redirect-page.component'

import { QRCodeModule } from 'angularx-qrcode';




@NgModule({
  declarations: [
    AppComponent,
    BankingPageComponent,
    PopupAddFoundsComponent,
    FailedPaymentComponent,
    SuccessfulPaymentComponent,
    PopupAddInfosComponent,
    RedirectPageComponent,
  ],
  imports: [
    QRCodeModule,
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    MatDividerModule,
    MatProgressBarModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
