<div class="countainer">
    <div class="text"> Quel est le montant que vous souhaitez recharger ? </div>

    <form class="example-form" [style.fontSize.px]="20">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Montant</mat-label>
          <input type="number" matInput [formControl]="montant" placeholder="1,500.00">
          <span matSuffix>Dhs</span>
          <mat-error *ngIf="montant.hasError('min') && !montant.hasError('required')">
            Le minimum 1 dhs
          </mat-error>
          <mat-error *ngIf="montant.hasError('required')">
            Vous devez saisir un <strong>montant </strong>!
          </mat-error>
        </mat-form-field>
      </form>

    <mat-divider></mat-divider>
    
    <div class="actions">
        <button [disabled]="montant.invalid" class="validate" (click)="validate()" mat-raised-button>Valider</button>
        <button class="cancel" mat-raised-button mat-dialog-close >Annuler</button>
    </div>
</div>
