import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from 'src/app/services/loader.service';
import { BankingService } from '../../services/banking.service'
import { PopupAddFoundsComponent } from '../popup-add-founds/popup-add-founds.component';
import { PopupAddInfosComponent } from '../popup-add-infos/popup-add-infos.component';



@Component({
  selector: 'app-banking-page',
  templateUrl: './banking-page.component.html',
  styleUrls: ['./banking-page.component.css']
})
export class BankingPageComponent implements OnInit {

  link : string = null;
  showQrCode = false;

  width;
  height;

  numCard
  clientCardInformations

  constructor(public loaderService: LoaderService, private bankingService: BankingService, private router: ActivatedRoute, public dialog: MatDialog) { 
    this.link = window.location.href
  }

  ngOnInit(): void {


    this.width = window.innerWidth + 'px'
    this.height = window.innerHeight + 'px'

    this.router.params.subscribe(params => {
      this.numCard = params.num_card
      this.bankingService.getClientCardInformations(params.num_card).subscribe( result => {
        this.clientCardInformations = result
        if(this.clientCardInformations.informations_client.nom_client == "" || this.clientCardInformations.informations_client.nom_client == null){
          this.dialog.open(PopupAddInfosComponent, {
            maxWidth: this.width,
            maxHeight: this.height,
            height: this.height,
            width: this.width,
            panelClass: 'full-screen-modal',
            data: {
              idClient: this.clientCardInformations.informations_client.id_client
            }
          })
        }
      })
    })

    
  }

  ShowOrHideQrCode(){
    this.showQrCode = !this.showQrCode

    if(this.showQrCode){
      setTimeout(()=>{
        const qrcode = document.getElementById('qrcode')
        qrcode.classList.add('showqrcode')
      }, 300)
    }else{
      setTimeout(()=>{
        const qrcode = document.getElementById('info')
        qrcode.classList.add('showqrcode')
      }, 1000)
    }

    
  }


  addFounds(){
      this.dialog.open(PopupAddFoundsComponent, {
        width: '340px',
        data: {
          numCard : this.numCard,
          name : this.clientCardInformations.informations_client.nom_client,  
        }
      });


    
  }
}
