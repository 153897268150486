import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-successful-payment',
  templateUrl: './successful-payment.component.html',
  styleUrls: ['./successful-payment.component.css']
})
export class SuccessfulPaymentComponent implements OnInit {

  width 
  height

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.width = window.innerWidth + 'px'
    this.height = window.innerHeight  + 'px'
  }

  redirect(){
    this.route.queryParamMap.subscribe(params => {
      const numCard = params.get('num_carte')
      this.router.navigate(['/'+ numCard]);
    })
  }

}
