import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class BankingService {

  constructor(private http: HttpClient, private router: Router) { }

  getClientCardInformations(numCard){
    const url = 'https://api-legacy.lacaisse.ma/getSoldeEkaFestival.php'
    const params = new HttpParams().append('num_carte', numCard)
    return this.http.get(url, {params}) 
  }


  getOrderId(numCard, montant){
    const url = 'https://eka.lalivraison.ma/addRecharge.php'
    const params = new HttpParams().append('num_carte', numCard)
                                   .append('montant', montant)
    
    return this.http.get(url, {params}) 
  }


  updateClientCardInformations(clientName, phoneNumber, clientId){
    const url = 'https://eka.lalivraison.ma/updateClient3.php'
    const params = new HttpParams().append('nom_client', clientName)
                                   .append('tel_client', phoneNumber)
                                   .append('id_client', clientId)
                                   .append('email_client', '')
                                   .append('date_naissance', '')
                                   .append('id_caisse', '990')
    return this.http.get(url, {params}) 
  }


}
