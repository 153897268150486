import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { BankingService } from '../../services/banking.service'

@Component({
  selector: 'app-popup-add-infos',
  templateUrl: './popup-add-infos.component.html',
  styleUrls: ['./popup-add-infos.component.css']
})
export class PopupAddInfosComponent implements OnInit {

  width
  height


  fullName = new FormControl('', [
    Validators.required,
  ]);

  phoneNumber = new FormControl('', [
    Validators.required,
  ]);

  constructor(@Inject(MAT_DIALOG_DATA) public data, private bankingService :BankingService) { }

  ngOnInit(): void {
    this.width = window.innerWidth + 'px'
    this.height = window.innerHeight + 'px'
  }

  

  validate(){
    const clientName = this.fullName.value
    const phoneNumber = this.phoneNumber.value
    const clientId = this.data.idClient
    this.bankingService.updateClientCardInformations(clientName, phoneNumber, clientId).subscribe(res => location.reload())
  }

}
