<div class="only-for-mobile">
    Uniquement pour mobile
</div>

<div 
    class="countainer"  
    [ngStyle]="{'width': width, 'min-height': height}"
>
    <div class="logo">
        <img src="../../../assets/images/logoEka.png" alt="">
    </div>

    <div class="add-founds">
        <div class="first-line"> Veuillez saisir vos informations pour activer votre carte de paiement ! </div>
        <div class="second-line">
            <form class="example-form" [style.fontSize.px]="17">

                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Nom complet*</mat-label>
                  <input type="text" matInput [formControl]="fullName" placeholder="Ahmed Berrada">
                  <mat-error *ngIf="fullName.hasError('required')">
                    Vous devez saisir un <strong>nom complet </strong>!
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Numéro de téléphone*</mat-label>
                    <input type="text" matInput [formControl]="phoneNumber" placeholder="0612345678">
                    <mat-error *ngIf="phoneNumber.hasError('required')">
                      Vous devez saisir un <strong>numéro de téléphone </strong>!
                    </mat-error>
                  </mat-form-field>

              </form>
        </div>
        <mat-divider></mat-divider>
        <div class="third-line">
            <button [disabled]="fullName.invalid || phoneNumber.invalid" class="validate" (click)="validate()" mat-raised-button>Valider</button>
        </div>
    </div>

    <div class="shape">
    </div>

    <div class="powered">
        <p>powered by</p>
        <img style="border-radius: 0px;" src="../../../assets/images/lacaisse.ma white logo.png" alt="">
    </div>
    
</div>


