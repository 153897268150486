import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { BankingPageComponent } from './components/banking-page/banking-page.component';
import { SuccessfulPaymentComponent } from './components/successful-payment/successful-payment.component';
import { FailedPaymentComponent } from './components/failed-payment/failed-payment.component';


const routes: Routes = [
  { path: "", 
    component: HomeComponent 
  },
  { path: "successful-payment",
    component: SuccessfulPaymentComponent
  },
  { path: "failed-payment",
    component: FailedPaymentComponent
  },
  { path: ":num_card", 
    component: BankingPageComponent 
  },
  

  

  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
