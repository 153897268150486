<div class="only-for-mobile">
    Uniquement pour mobile
</div>

<img style="display: none;" src="../../../assets/images/buying.png" alt="">
<img style="display: none;" src="../../../assets/images/foundsAdded.png" alt="">

<div 
    class="loading-page"
    [ngStyle]="{'width': width, 'height': height}"
    *ngIf="loaderService.isLoading | async" 
>
    <div class="text">Chargement ...</div>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<div 
    class="countainer"  
    [ngStyle]="{'width': width, 'min-height': height}"
>
    <div class="logo">
        <img src="../../../assets/images/logoEka.png" alt="">
    </div>


    <div class="card-info" *ngIf="clientCardInformations">
        <div class="infos" *ngIf="!showQrCode" id="info">
            <div class="first-line">
                <div class="card-number">
                    <span>Bonjour </span>
                    <span class="bonjour"> {{clientCardInformations.informations_client.nom_client}} </span>
                </div>
            </div>
            <div class="second-line">
                <div class="full-name">
                 <span>Le solde de votre compte est : </span>
                </div>
            </div>
            <div class="third-line">
                <span class="solde">{{clientCardInformations.informations_client.solde | number : '1.2-2' }} Dhs</span>
            </div>
        </div>

        <div class="generated_qr_code" *ngIf="showQrCode" id="qrcode">
            <qrcode class="code" [qrdata]="link" [width]="160" [height]="160" [errorCorrectionLevel]="'M'"></qrcode>
        </div>
    </div>

    <div class="add-founds">
        <button (click)="ShowOrHideQrCode()" >
            <div><img src="../../../assets/images/QRCODE.svg" alt=""></div>
            <div  *ngIf="showQrCode">Masquer mon QR code</div>
            <div  *ngIf="!showQrCode">Afficher mon QR code</div>
        </button>
    </div>

    <div class="add-founds">
        <button (click)="addFounds()" >
            <div><img src="../../../assets/images/creditCardwhite.svg" alt=""></div>
            Alimenter mon compte par carte</button>
    </div>

    <div class="history" *ngIf="clientCardInformations">
        <table>
            <thead>
                <tr>
                    <th class="transactions">Transactions</th>
                      <th class="details">Details</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let transaction of clientCardInformations.historique" class="test">
                    <td class="transactions">
                        <div class="transaction-card">
                            <div class="transaction-icon" *ngIf="transaction.montant >= 0">
                                <img src="../../../assets/images/foundsAdded.png" alt="">
                            </div>
                            <div class="transaction-icon" *ngIf="transaction.montant < 0">
                                <img src="../../../assets/images/buying.png" alt="">
                            </div>
                            <div class="transaction-details">
                                <div class="transaction-note">
                                    {{ transaction.note }}
                                </div>
                                <div class="transaction-date">
                                    {{ transaction.date }}
                                </div>
                            </div>
                            
                        </div>
                    </td>
                    <td class="details"> 
                        <div class="transaction-montant" style="color: green;" *ngIf="transaction.montant >= 0">
                            {{ transaction.montant }} <span class="currency">Dhs</span> 
                        </div>
                        <div class="transaction-montant" style="color: red;" *ngIf="transaction.montant < 0"> 
                            {{ transaction.montant }} <span class="currency">Dhs</span> 
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="shape">
    </div>

    <div class="powered">
        <p>powered by</p>
        <img style="border-radius: 0px;" src="../../../assets/images/lacaisse.ma white logo.png" alt="">
    </div>

    
</div>


