import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-redirect-page',
  templateUrl: './redirect-page.component.html',
  styleUrls: ['./redirect-page.component.css']
})
export class RedirectPageComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data) { }

  url=""

  ngOnInit(): void {
    this.url = this.data.url
  }

}
